<template>
  <v-container
    fluid
    style="padding-top: 0;">
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('hashtag.hashtagList')"
          color="green"
          flat
          full-width
        >
          <template v-if="isManageHashtag() && !isEntityDisabled()">
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddCustomerGroup(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="hashtagHeaders"
            :items="hashtagList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                v-if="header.value"
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              v-slot:items="props"
            >
              <td
                v-for="(header, index) in hashtagHeaders"
                :key="header.value + index"
                class="text-xs-center">
                <span v-if="header.value == 'actions'">
                  <v-menu
                    v-model="props.item.selectedButton"
                    offset-y
                    transition="slide-y-transition"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="indigo"
                        dark
                        v-on="on">
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <!-- Send voucher -->
                      <v-tooltip
                        top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :disabled="(props.item['count'] <= 0) || isEntityDisabled()"
                            class="mx-2"
                            fab
                            small
                            color="brown"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onSendVoucherToHashtag(props.item)">
                            <v-icon dark>mdi-send</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('hashtag.distributeVoucher') }}</span>
                      </v-tooltip>
                      <!-- Edit hashtag -->
                      <v-tooltip
                        top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            color="primary"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onShowModalAddCustomerGroup(props.item)">
                            <v-icon dark>mdi-account-details</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('common.detail') }}</span>
                      </v-tooltip>
                      <!-- Delete -->
                      <v-tooltip
                        top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :disabled="!isManageHashtag() || isEntityDisabled()"
                            class="mx-2"
                            fab
                            small
                            color="red"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onConfirmDeleteHashtag(props.item.id)">
                            <v-icon dark>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('common.delete') }}</span>
                      </v-tooltip>
                    </v-card>
                  </v-menu>
                </span>
                <span v-else>{{ props.item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="paginate.totalPage"
      :current-page="paginate.currentPage"
      :row-per-page="paginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <customerGroupDetailModal
      ref="customerGroupDetailModal"
      @onCustomerGroupChanged="onGetHashtagList"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteHashtag()"
    />
    <assignVoucherHashtagModal
      ref="assignVoucherHashtagModal"
      @transactionVoucherSuccess="onTransactionVoucherSuccess()"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CustomerGroupDetailModal from './CustomerGroupDetailModal'
import ConfirmModal from 'Components/ConfirmModal'
import AssignVoucherHashtagModal from './../EVoucher/AssignVoucherHashtagModal'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import EntityType from 'enum/entityType'
export default {
  components: {
    ConfirmModal,
    CustomerGroupDetailModal,
    AssignVoucherHashtagModal
  },
  data: () => ({
    hashtagHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'actions.name',
        value: 'name',
        align: 'center'
      },
      {
        sortable: false,
        text: 'hashtag.userCreated',
        value: 'userCreated',
        align: 'center'
      },
      // {
      //   sortable: false,
      //   text: 'hashtag.entityCreated',
      //   value: 'entityCreated',
      //   align: 'center'
      // },
      {
        sortable: false,
        text: 'hashtag.count',
        value: 'count',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    hashtagList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    confirmModalTitle: '',
    hashtagSelected: {
      id: null
    }
  }),
  computed: {
    ...mapGetters([
      'CUSTOMER_GROUP_DATA',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    CUSTOMER_GROUP_DATA () {
      let res = this.CUSTOMER_GROUP_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let hashtagsData = res.results
      this.hashtagList = []
      for (let i = 0, size = hashtagsData.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: hashtagsData[i].id,
          name: hashtagsData[i].name,
          userCreated: functionUtils.concatFullname(hashtagsData[i].user_created.last_name, hashtagsData[i].user_created.first_name),
          entityCreated: hashtagsData[i].entity_created.name,
          count: hashtagsData[i].number_member,
          selectedButton: false
        }
        this.hashtagList.push(obj)
      }
    }
  },
  created () {
    this.onGetHashtagList()
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Show modal add customer group
     */
    onShowModalAddCustomerGroup: function (item) {
      this.$refs.customerGroupDetailModal.onShowModal(item)
    },
    /**
     * Get hashtag list
     */
    onGetHashtagList: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage
        }
      }
      this.GET_CUSTOMER_GROUP_LIST(filter)
    },
    /**
     * Delete hashtag
     */
    onDeleteHashtag: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.DELETE_CUSTOMER_GROUP({ id: this.hashtagSelected.id }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.onGetHashtagList()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Confirm delete hashtag
     */
    onConfirmDeleteHashtag: function (hashtagId) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.hashtagSelected.id = hashtagId
      this.confirmModalTitle = this.$t('hashtag.confirmToDelete')
      this.$refs.confirmModal.onShowModal()
    },
    onTransactionVoucherSuccess: function () {
      // this.getEVoucherList()
    },
    onSendVoucherToHashtag: function (hasgtag) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      if (hasgtag.count > 0) {
        this.$refs.assignVoucherHashtagModal.onShowModal(hasgtag)
      } else {
        this.ON_SHOW_TOAST({
          message: this.$t('hashtag.isEmpty'),
          styleType: ToastType.ERROR
        })
      }
    },
    /**
     * Check is supplier
     */
    isManageHashtag: function () {
      return (functionUtils.isEntityType(EntityType.SUPPLIER) || functionUtils.isEntityType(EntityType.AGENT))
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.onGetHashtagList()
    },
    ...mapActions([
      'GET_CUSTOMER_GROUP_LIST',
      'DELETE_CUSTOMER_GROUP',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style>

</style>
