<template>
  <v-dialog
    v-model="isShowModal"
    max-width="600px">
    <v-card>
      <material-card
        :title="isAssignCustomer ? $t('assignVoucher.releaseToCustomer') : $t('assignVoucher.releaseToAgent')"
        tile
        color="green"
        full-width
      >
        <v-container>
          <v-layout wrap>
            <v-flex
              xs12
              md12
            >
              <!-- <span>{{ title }}</span> -->
              <div>
                <p>{{ isAssignCustomer ? $t('assignVoucher.toCustomer') : $t('assignVoucher.toAgent') }}: <span style="font-weight: bold;">{{ receiver }}</span></p>
                <p>{{ $t('assignVoucher.totalVoucherRelease') }}: <span style="font-weight: bold;">{{ quantity }}</span></p>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="isShowModal = false"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm($event)"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data () {
    return {
      isShowModal: false,
      title: '',
      quantity: 0,
      isAssignCustomer: false,
      receiver: ''
    }
  },
  methods: {
    /**
     * Confirm
     */
    onConfirm: function (event) {
      event.preventDefault()
      this.$emit('onConfirm')
      this.isShowModal = false
    },
    /**
     * Show modal
     */
    onShowModal: function (data) {
      this.isShowModal = true
      //   this.title = data.title
      this.isAssignCustomer = data.isAssignForCustomer
      this.receiver = data.receiver
      this.quantity = data.quantity
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    }
  }
}

</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
</style>
