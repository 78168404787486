<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="hashtagSelected.id ? '800px' : '600px'"
    persistent>
    <v-card>
      <material-card
        :title="hashtagSelected.id ? $t('hashtag.updateHashtag') : $t('hashtag.addNewHashtag')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <v-flex
                xs12
                sm12
                md6
                style="padding: 0;"
              >
                <v-text-field
                  :disabled="isEntityDisabled()"
                  :rules="[ruleRequiredValue]"
                  v-model="name"
                  :label="$t('actions.name')"
                  :name="$t('actions.name')"
                  type="text"
                  class="required"
                />
              </v-flex>
            </v-layout>
            <v-layout
              v-if="hashtagSelected.id"
              column
              style="max-height: 50vh">
              <span class="font-weight-bold mb-2">{{ $t('hashtag.listOfCustomersInHashtag') }}</span>
              <div>
                <v-btn
                  v-if="!isEntityDisabled()"
                  small
                  color="success darken-1"
                  style="width: fit-content; float: right;"
                  @click="onShowModalAddCustomerIntoHashtag">{{ $t('hashtag.addCustomerToTheHashtag') }}</v-btn>
              </div>
              <v-flex
                class="mt-0 pt-0"
                xs12
                sm12
                md12
                style="overflow: auto; padding: 0;"
              >
                <v-progress-linear
                  v-show="progressBar"
                  :indeterminate="progressBar"
                  color="success"
                  height="5"
                  value="30"
                />
                <v-data-table
                  :no-data-text="$t('common.noDataAvailable')"
                  :headers="customerHeaders"
                  :items="customerList"
                  hide-actions
                >
                  <template
                    slot="headerCell"
                    slot-scope="{ header }"
                  >
                    <span
                      v-if="header.value"
                      class="text--darken-3 font-medium"
                      v-text="$t(header.text)"
                    />
                  </template>
                  <template
                    v-slot:items="props"
                  >
                    <td
                      v-for="(header, index) in customerHeaders"
                      :key="header.value + index">
                      <span v-if="header.value == 'actions'">
                        <v-tooltip
                          top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :disabled="isEntityDisabled()"
                              class="mx-2"
                              fab
                              small
                              color="red"
                              style="color: #ffffff;"
                              v-on="on"
                              @click="removeCustomerGetOutHashtag(props.item.id)">
                              <v-icon dark>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                      </span>
                      <span v-else>{{ props.item[header.value] }}</span>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
              <pagination
                :total="customerPaginate.totalPage"
                :current-page="customerPaginate.currentPage"
                :row-per-page="customerPaginate.rowPerPage"
                @onPageChange="onPageChange"
              />
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-if="!isEntityDisabled()"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <addCustomerIntoHashtagModal
      ref="addCustomerIntoHashtagModal"
      @addCustomerIntoGroupSuccess="onAddCustomerIntoGroupSuccess"/>
  </v-dialog>
</template>

<script>
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import AddCustomerIntoHashtagModal from './AddCustomerIntoHashtagModal'
export default {
  components: {
    AddCustomerIntoHashtagModal
  },
  data: () => ({
    name: '',
    isShowModal: false,
    valid: false,
    hashtagSelected: {
      id: null
    },
    customerList: [],
    customerIds: [],
    customerHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'actions.name',
        value: 'name'
      },
      {
        sortable: false,
        text: 'userProfile.phone',
        value: 'phone'
      },
      {
        sortable: false,
        text: 'userProfile.email',
        value: 'email'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions'
      }
    ],
    customerPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    progressBar: false
  }),
  computed: {
    ...mapGetters([
      'CUSTOMER_IN_GROUP_DATA'
    ])
  },
  watch: {
    CUSTOMER_IN_GROUP_DATA () {
      let res = this.CUSTOMER_IN_GROUP_DATA
      let data = res.results
      this.customerPaginate.currentPage = res.paginate.currentPage
      this.customerPaginate.totalPage = res.paginate.totalPage
      this.customerPaginate.rowPerPage = res.paginate.perPage
      this.customerList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let customerObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          name: functionUtils.concatFullname(data[i].last_name, data[i].first_name),
          phone: data[i].profile && data[i].profile.phone,
          email: data[i].email,
          gender: data[i].profile && data[i].profile.gender,
          birthDate: data[i].profile && data[i].profile.dob,
          id: data[i].id
        }
        this.customerList.push(customerObj)
      }
      this.progressBar = false
    }
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() && functionUtils.isCurrentEntityExpired()
    },
    /**
     * Add customer into group success
     */
    onAddCustomerIntoGroupSuccess: function () {
      this.getCustomerList()
      this.$emit('onCustomerGroupChanged')
    },
    /**
     * Remove customer get out hashtag
     */
    removeCustomerGetOutHashtag: function (customerId) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        hashtagId: this.hashtagSelected.id,
        customerId: customerId
      }
      this.REMOVE_CUSTOMER_GET_OUT_GROUP(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getCustomerList()
          this.$emit('onCustomerGroupChanged')
        }.bind(this)
      )
    },
    /**
     * Show modal add customer into hashtag
     */
    onShowModalAddCustomerIntoHashtag: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.addCustomerIntoHashtagModal.onShowModal(this.hashtagSelected.id)
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Show modal
     */
    onShowModal: function (item) {
      if (item) {
        this.hashtagSelected.id = item.id
        this.name = item.name
        this.getCustomerList()
      } else {
        this.hashtagSelected.id = null
        this.name = null
      }
      this.isShowModal = true
    },
    /**
     * Get list entities by type
     */
    getCustomerList: function () {
      let filter = {
        params: {
          page: this.customerPaginate.currentPage,
          hashtagId: this.hashtagSelected.id,
          getInGroup: true
        }
      }
      this.progressBar = true
      this.GET_CUSTOMER_IN_OUT_GROUP(filter).then(
        function () {
          this.progressBar = false
        }.bind(this)
      ).catch(
        function () {
          this.progressBar = false
        }.bind(this)
      )
    },
    /**
     * Confirm modal
     */
    onConfirm: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      if (this.$refs.form.validate()) {
        let filter = {
          name: this.name,
          customerIds: this.customerIds
        }
        if (this.hashtagSelected.id) {
          this.UPDATE_CUSTOMER_GROUP({ id: this.hashtagSelected.id, ...filter }).then(
            function () {
              this.$emit('onCustomerGroupChanged')
              this.isShowModal = false
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
            }.bind(this)
          ).catch(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }.bind(this)
          )
        } else {
          this.CREATE_CUSTOMER_GROUP(filter).then(
            function () {
              this.$emit('onCustomerGroupChanged')
              this.isShowModal = false
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
            }.bind(this)
          ).catch(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }.bind(this)
          )
        }
      }
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.customerPaginate.currentPage = page
      this.getCustomerList()
    },
    ...mapActions([
      'CREATE_CUSTOMER_GROUP',
      'UPDATE_CUSTOMER_GROUP',
      'ON_SHOW_TOAST',
      'GET_CUSTOMER_IN_OUT_GROUP',
      'REMOVE_CUSTOMER_GET_OUT_GROUP'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
