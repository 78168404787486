<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="MODAL_WIDTH"
    persistent>
    <v-card>
      <material-card
        :title="$t('hashtag.distributeVoucherToGroup', {name: hashtag.name})"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <div class="mb-3"><span>{{ $t('hashtag.numberOfMember') }} <b>{{ hashtag.count }}</b></span></div>
            <div>
              <span>{{
                $t("infoVoucher.voucherInformation")
              }}</span>
              <v-layout
                row
                wrap>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-autocomplete
                    :rules="[ruleRequiredValue]"
                    v-model="workpieceInfo.id"
                    :items="workpieceInfo.data"
                    :clearable="true"
                    :label="forConasi ? $t('assignVoucher.selectTheWorkPieceCode') : $t('assignVoucher.selectVoucher')"
                    class="required"
                    @change="onWorkpieceChange"
                  >
                    <template v-slot:item="data">
                      <span>{{ data.item.text }} <span class="grey--text font-small">[{{ data.item.code }}]</span></span>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <!-- Prefix and suffix -->
                <v-flex
                  v-if="forConasi"
                  xs12
                  sm4
                  md4>
                  <v-autocomplete
                    :rules="[ruleRequiredValue]"
                    v-model="issuanceId"
                    :items="issuanceList"
                    :clearable="true"
                    :label="$t('assignVoucher.chooseIssuance')"
                    type="text"
                    class="required"
                    @change="onChangeIssuance"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ concatSuffixPrefix(data.item) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ concatSuffixPrefix(data.item) }}
                    </template>
                  </v-autocomplete>
                </v-flex>
                <!-- Voucher available quantity -->
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-text-field
                    :rules="[ruleMustLessThanAvailableQuantity]"
                    v-model="quantityVoucherAvailableOfEntity"
                    :label="$t('evoucher.numberOfVouchersAvailable')"
                    :name="$t('evoucher.numberOfVouchersAvailable')"
                    disabled
                    type="text"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-text-field
                    v-model="priceFormat"
                    :label="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                    :name="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                    disabled
                  />
                </v-flex>
                <!-- Total price format -->
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-text-field
                    v-model="totalPriceFormat"
                    :label="$t('workpieceDetail.totalPrice')"
                    :name="$t('workpieceDetail.totalPrice')"
                    disabled
                  />
                </v-flex>
              </v-layout>
            </div>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirmAssignVoucherModal
      ref="confirmAssignVoucherModal"
      @onConfirm="validate()" />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import EntityType from 'enum/entityType'
import stringUtils from 'utils/stringUtils'
import VoucherValue from 'enum/voucherValue'
import ConfirmModal from 'Components/ConfirmModal'
// import VoucherPrintType from 'enum/voucherPrintType'
import ConfirmAssignVoucherModal from './ConfirmAssignVoucherModal'
import CustomerInfoModal from './CustomerFormInfoModal'
import VoucherTransactionValue from 'enum/voucherTransactionValue'
export default {
  components: {
    ConfirmModal,
    ConfirmAssignVoucherModal,
    CustomerInfoModal
  },
  data () {
    return {
      valid: false,
      isLoading: false,
      isShowModal: false,
      workpieceInfo: {
        data: [],
        id: null
      },
      quantityVoucherAvailableOfEntity: 0,
      quantityAssign: 0,
      price: 0,
      priceFormat: 0,
      totalPriceFormat: 0,
      MODAL_WIDTH: '800px',
      issuanceId: null,
      issuanceList: [],
      forConasi: FOR_CONASI_TARGET,
      voucherValueType: VoucherValue.PRICE,
      VoucherValue: VoucherValue,
      VoucherTransactionValue: VoucherTransactionValue,
      hashtag: {
        id: null,
        name: '',
        count: 0
      }
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA',
      'OBJECT_SETTING_DATA',
      'ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA',
      'GET_ISSUANCE_LIST_DATA'
    ])
  },
  watch: {
    QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA () {
      let data = this.QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA
      this.quantityVoucherAvailableOfEntity = data.quantity_voucher_available
        ? data.quantity_voucher_available : 0
      this.price = data.unit_price
      this.priceFormat = functionUtils.convertFormatNumberToMoney(this.price)
      this.quantityAssign = this.quantityVoucherAvailableOfEntity
      this.calculatorTotalPrice(this.price, this.quantityAssign)
    },
    ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA () {
      let data = this.ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA
      this.workpieceInfo.data = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          text: data[i].name,
          code: data[i].code,
          type: data[i].type,
          conditionsOfUse: data[i].conditions_of_use,
          description: data[i].description,
          supplierImage: data[i].owner.image_url,
          supplierId: data[i].owner.id
        }
        this.workpieceInfo.data.push(obj)
      }
      // Set issuance type value
      let workpieceSelected = this.workpieceInfo.data.filter(element => {
        return element.value === this.workpieceInfo.id
      })
      this.voucherValueType = workpieceSelected.length > 0 ? workpieceSelected[0].type : VoucherValue.PRICE
    },
    GET_ISSUANCE_LIST_DATA () {
      let data = this.GET_ISSUANCE_LIST_DATA
      this.issuanceList = []
      this.issuanceId = null
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          prefix: data[i].prefix,
          suffix: data[i].suffix
        }
        this.issuanceList.push(obj)
      }
    }
  },
  created () {
  },
  methods: {
    /**
     * Get prefix suffix
     */
    getIssuanceList: function (workpieceId) {
      let filter = {
        params: {
          workpieceId: workpieceId
        }
      }
      this.GET_ISSUANCE_LIST(filter)
    },
    /**
     * Prefix suffix change
     */
    onChangeIssuance: function () {
      this.getQuantityEVoucherAvailableOfEntity(this.workpieceInfo.id)
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleMustLessThanAvailableQuantity (value) {
      return validationRules.ruleMustLessThan(this.hashtag.count, value, this.$t('hashtag.quantityNotEnough'))
    },
    /**
     * Get e voucher issuance list by entity
     */
    getEVoucherIssuanceListByEntity: function () {
      this.quantityVoucherAvailableOfEntity = 0
      this.workpieceInfo.id = null
      let filter = {
        params: {
          isAssignForCustomer: true
        }
      }
      this.GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST(filter)
    },
    /**
     * Get quantity e voucher available of entity
     */
    getQuantityEVoucherAvailableOfEntity: function (workpieceId) {
      let transactionType = EntityType.CUSTOMER
      let filter = {}
      if (!functionUtils.isNull(workpieceId)) {
        filter = {
          params: {
            workpieceId: workpieceId,
            transactionType: transactionType,
            issuanceId: this.issuanceId
          }
        }
      } else {
        filter = {
          params: {
            transactionType: transactionType,
            issuanceId: this.issuanceId
          }
        }
      }
      this.GET_QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY(filter)
    },
    /**
     * Change e voucher issuance
     */
    onWorkpieceChange: function (workpieceId) {
      let workpieceSelected = this.workpieceInfo.data.filter(element => {
        return element.value === workpieceId
      })
      if (workpieceSelected.length > 0) {
        this.voucherValueType = workpieceSelected[0].type
      } else {
        this.voucherValueType = VoucherValue.PRICE
      }
      this.getQuantityEVoucherAvailableOfEntity(workpieceId)
      this.getIssuanceList(workpieceId)
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    concatSuffixPrefix: function (suffixPrefix) {
      return functionUtils.concatSuffixPrefix(suffixPrefix)
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.isShowModal = false
      this.quantityVoucherAvailableOfEntity = 0
      this.workpieceInfo.id = null
      this.price = 0
      this.priceFormat = 0
      this.totalPriceFormat = 0
      this.MODAL_WIDTH = '800px'
      this.issuanceId = null
    },
    /**
     * Calculator total price
     */
    calculatorTotalPrice: function (price, quantity) {
      // Total price format
      this.totalPriceFormat = functionUtils.convertFormatNumberToMoney(price * quantity)
    },
    /**
     * Show modal
     */
    onShowModal: function (hashtag) {
      this.getEVoucherIssuanceListByEntity()
      this.hashtag.id = hashtag.id
      this.hashtag.name = hashtag.name
      this.hashtag.count = hashtag.count
      this.price = 0
      this.priceFormat = 0
      this.issuanceList = []
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.setDefaultData()
    },
    /**
     * Handle assign voucher
     */
    handleAssignVoucher: function () {
      let filter = {
        customerGroupId: this.hashtag.id,
        workpieceId: this.workpieceInfo.id,
        issuanceId: this.issuanceId,
        valueType: this.voucherValueType,
        customerUrl: stringUtils.CUSTOMER_URL,
        discountType: VoucherTransactionValue.PERCENT
      }
      this.isLoading = true
      this.TRANSACTION_VOUCHER_TO_GROUP_CUSTOMER(filter)
        .then(
          function (res) {
            this.isLoading = false
            this.setDefaultData()
            this.$emit('transactionVoucherSuccess')
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
          }.bind(this)
        )
        .catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styleType: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    validate: function () {
      if (this.$refs.form.validate()) {
        this.handleAssignVoucher()
      }
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let receiver = this.$t('hashtag.distributeVoucherToGroup', { name: this.hashtag.name })
        let data = {
        // title: title,
          quantity: this.hashtag.count,
          receiver: receiver,
          isAssignForCustomer: true
        }
        this.$refs.confirmAssignVoucherModal.onShowModal(data)
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'TRANSACTION_VOUCHER_TO_GROUP_CUSTOMER',
      'GET_QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY',
      'GET_OBJECT_SETTING',
      'GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST',
      'GET_ISSUANCE_LIST'
    ])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
</style>
