
<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="600px">
    <v-card ref="card">
      <material-card
        :title="$t('customer.info')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <!-- Customer email -->
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="customerEmail"
                  :label="$t('user.email')"
                  :name="$t('user.email')"
                  disabled
                  type="text"
                />
              </v-flex>
              <!-- Customer full name -->
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidFullname]"
                  v-model="customerInfo.fullName"
                  :label="$t('customer.nameCustomer')"
                  :name="$t('customer.nameCustomer')"
                  class="required"
                  type="text"
                />
              </v-flex>
              <!-- Customer phone -->
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  :disabled="isExistingCustomer()"
                  v-model="customerInfo.phone"
                  :label="$t('user.phone')"
                  :name="$t('user.phone')"
                  type="text"
                />
              </v-flex>
              <!-- Customer gender -->
              <v-flex
                xs12
                sm6
                md6
              >
                <v-select
                  :disabled="isExistingCustomer()"
                  v-model="customerInfo.gender"
                  :items="genderList"
                  :label="$t('user.gender')"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <!-- Customer birth date -->
              <!-- <v-flex
                xs12
                sm6
                md6
              >
                <v-menu
                  ref="menu"
                  v-model="menuBirthDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :disabled="isExistingCustomer()"
                      v-model="customerInfo.birthDateFormatted"
                      :label="$t('user.dob')"
                      persistent-hint
                      @blur="date = parseDate(customerInfo.birthDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="customerInfo.birthDate"
                    no-title
                    @input="menuBirthDate = false"
                  />
                </v-menu>
              </v-flex> -->
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import stringUtils from 'utils/stringUtils'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
export default {
  data: () => ({
    isShowModal: false,
    valid: false,
    customerEmail: null,
    customerInfo: {
      id: null,
      phone: null,
      gender: null,
      fullName: null,
      birthDate: null,
      birthDateFormatted: null
    },
    genderList: stringUtils.GenderList,
    menuBirthDate: false,
    forConasi: FOR_CONASI_TARGET
  }),
  watch: {
    'customerInfo.birthDate' (val) {
      this.customerInfo.birthDateFormatted = this.formatDate(this.customerInfo.birthDate)
    }
  },
  methods: {
    /**
     * Format date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      let vm = this
      Object.keys(vm.customerInfo).forEach(function (key, value) {
        vm.customerInfo[key] = null
      })
    },
    isExistingCustomer: function (checkName = false) {
      return this.customerInfo.id > 0
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidFullname (value) {
      return validationRules.ruleValidFullname(value)
    },
    /**
     * Show modal
     */
    onShowModal: function (email) {
      this.isShowModal = true
      this.setDefaultData()
      this.customerEmail = email
      let filter = {
        params: {
          email: this.customerEmail
        }
      }
      this.setDefaultData()
      this.GET_USER_INFO(filter).then(
        function (res) {
          let data = res.data
          this.customerInfo.id = data.id
          this.customerInfo.phone = data.phone
          this.customerInfo.fullName = functionUtils.concatFullname(data.last_name, data.first_name)
          this.customerInfo.gender = data.gender
          this.customerInfo.birthDateFormatted = dateUtils.formatBeautyDate(data.birth_date, null)
        }.bind(this)
      ).catch(
        function () {}
      )
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        this.isShowModal = false
        this.$emit('onConfirm', this.customerInfo)
      }
    },
    ...mapActions([
      'GET_USER_INFO'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
</style>
