<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('customer.customerList')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              column
              layout
              style="max-height: 50vh;">
              <v-layout
                wrap
                layout
                style="max-height: 50vh;">
                <v-flex
                  class="pt-0 mt-0"
                  xs12
                  md6
                  lg6>
                  <v-text-field
                    v-model="search.email"
                    :label="$t('customer.email')"
                    @keydown.enter="getCustomerList($event)"
                  />
                </v-flex>
                <!-- Btn add customers into group -->
                <v-flex
                  v-if="selected.length > 0"
                  class="pt-0 mt-0"
                  xs12
                  md6
                  lg6>
                  <v-btn
                    class="pull-right"
                    color="success"
                    dark
                    @click="onShowModalAddMultipleCustomer">
                    <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>
                    <span style="text-transform: none;">{{
                      $t('hashtag.addCustomerToSelected')
                    }}</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-flex
                xs12
                sm12
                md12
                style="overflow: auto; padding: 0;"
              >
                <v-progress-linear
                  v-show="progressBar"
                  :indeterminate="progressBar"
                  color="success"
                  height="5"
                  value="30"
                />
                <v-data-table
                  v-model="selected"
                  :no-data-text="$t('common.noDataAvailable')"
                  :headers="customerHeaders"
                  :items="customerList"
                  hide-actions
                  select-all
                >
                  <template
                    slot="headerCell"
                    slot-scope="{ header }"
                  >
                    <span
                      v-if="header.value"
                      class="text--darken-3 font-medium"
                      v-text="$t(header.text)"
                    />
                  </template>
                  <template
                    v-slot:items="props"
                  >
                    <td>
                      <v-checkbox
                        v-model="props.selected"
                        primary
                        hide-details />
                    </td>
                    <td
                      v-for="(header, index) in customerHeaders"
                      :key="header.value + index"
                      class="text-xs-center">
                      <span v-if="header.value == 'actions'">
                        <v-tooltip
                          top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mx-2"
                              fab
                              small
                              color="success"
                              style="color: #ffffff;"
                              v-on="on"
                              @click="addCustomerIntoHashtag(props.item.id)">
                              <v-icon dark>mdi-account-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('common.addInto') }}</span>
                        </v-tooltip>
                      </span>
                      <span>{{ props.item[header.value] }}</span>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
              <pagination
                :total="customerPaginate.totalPage"
                :current-page="customerPaginate.currentPage"
                :row-per-page="customerPaginate.rowPerPage"
                @onPageChange="onPageChange"
              />
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onAddMultipleCustomerIntoHashtag"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
export default {
  data: () => ({
    customerList: [],
    customerHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'actions.name',
        value: 'name'
      },
      {
        sortable: false,
        text: 'userProfile.phone',
        value: 'phone'
      },
      {
        sortable: false,
        text: 'userProfile.email',
        value: 'email'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions'
      }
    ],
    isShowModal: false,
    customerPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    hashtagId: null,
    valid: false,
    progressBar: false,
    search: {
      email: ''
    },
    selected: [],
    confirmModalTitle: ''
  }),
  computed: {
    ...mapGetters([
      'CUSTOMER_OUT_GROUP_DATA',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    CUSTOMER_OUT_GROUP_DATA () {
      let res = this.CUSTOMER_OUT_GROUP_DATA
      let data = res.results
      this.customerPaginate.currentPage = res.paginate.currentPage
      this.customerPaginate.totalPage = res.paginate.totalPage
      this.customerPaginate.rowPerPage = res.paginate.perPage
      this.customerList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let customerObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          name: functionUtils.concatFullname(data[i].last_name, data[i].first_name),
          phone: data[i].profile && data[i].profile.phone,
          email: data[i].email,
          gender: data[i].profile && data[i].profile.gender,
          birthDate: data[i].profile && data[i].profile.dob,
          id: data[i].id
        }
        this.customerList.push(customerObj)
      }
    }
  },
  methods: {
    /**
     * Show modal add multiple customer into hashtag
     */
    onShowModalAddMultipleCustomer: function () {
      this.confirmModalTitle = this.$t('hashtag.wantToAddCustomersToHashtag')
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Add multiple customer into hashtag
     */
    onAddMultipleCustomerIntoHashtag: function () {
      let customerIds = []
      for (let i = 0, size = this.selected.length; i < size; i++) {
        customerIds.push(this.selected[i].id)
      }
      let filter = {
        customerIds: customerIds,
        hashtagId: this.hashtagId
      }
      this.ADD_CUSTOMER_INTO_GROUP(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getCustomerList()
          this.$emit('addCustomerIntoGroupSuccess')
          this.isShowModal = false
        }.bind(this)
      )
    },
    /**
     * Add customer into hashtag
     */
    addCustomerIntoHashtag: function (customerId) {
      let filter = {
        customerIds: [customerId],
        hashtagId: this.hashtagId
      }
      this.ADD_CUSTOMER_INTO_GROUP(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getCustomerList()
          this.$emit('addCustomerIntoGroupSuccess')
          this.isShowModal = false
        }.bind(this)
      )
    },
    /**
     * Show modal
     */
    onShowModal: function (hashtagId) {
      this.isShowModal = true
      this.hashtagId = hashtagId
      this.getCustomerList()
    },
    /**
     * Get list entities by type
     */
    getCustomerList: function (event) {
      if (event) {
        event.preventDefault()
      }
      let filter = {
        params: {
          page: this.customerPaginate.currentPage,
          getInGroup: false,
          hashtagId: this.hashtagId,
          email: this.search.email
        }
      }
      this.progressBar = true
      this.GET_CUSTOMER_IN_OUT_GROUP(filter).then(
        function (res) {
          this.progressBar = false
        }.bind(this)
      ).catch(
        function () {
          this.progressBar = true
        }.bind(this)
      )
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.customerPaginate.currentPage = page
      this.getCustomerList()
    },
    /**
     * Confirm
     */
    onConfirm: function () {
    },
    ...mapActions([
      'GET_CUSTOMER_IN_OUT_GROUP',
      'ADD_CUSTOMER_INTO_GROUP',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
</style>
